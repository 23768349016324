<template>
  <div class="w-100 my-5">
    <div v-if="eventList">
      <div class="row mx-auto box-white">
        <div class="col-sm my-1 vertical-center">
          <img class="circle-image-30" :src="userInfo.thumbnailUrl || userInfo.photoUrl || assets.profile" v-if="userInfo" />
          <span class="ml-2 font-15 font-bold" v-if="userInfo">{{ userInfo.userName }}</span>
          <span class="ml-5 font-bold font-15 alpha-07">{{ $t('event') }}:</span>
          <span class="mx-5 font-bold font-20 color-blue">{{ items.length }}</span>
        </div>
        <div :class="`col-sm my-1 vertical-center ${isMobile ? '' : 'justify-content-end'}`">
          <input class="w-75" v-model="keyword" />
          <v-btn class="ml-2" icon small @click="keyword = ''" :disabled="!keyword">
            <i :class="`flaticon-circle ${keyword.length > 0 ? 'color-blue' : ''}`"></i>
          </v-btn>
        </div>
      </div>
      <div class="row my-5" v-if="(items.length > 0)">
        <div :class="`col-sm${colSmSize}`" v-for="(item, index) in items.slice(0, Math.min(pageIndex * PAGE_LIMIT_200, items.length))" :key="(item, index)">
          <v-card class="my-2 p-0 box-white d-flex flex-column">
            <v-img style="cursor: zoom-in" height="200px" :src="item.photoUrl" @click="openImageViewer(index)" />
            <div class="m-2 vertical-center" v-if="!userInfo">
              <img class="circle-image-30" :src="item.userPhotoUrl" />
              <span class="ml-2 ellipsis font-12" :title="item.userName">{{ item.userName }}</span>
            </div>
            <div class="mx-3 mt-1 vertical-center">
              <i class="fa fa-calendar-alt font-12 color-blue"></i>
              <span class="ml-2 font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
            </div>
            <span class="m-2 font-12" v-if="hostname === 'localhost'">ID: {{ item.eventId }}</span>
            <span class="mx-3 mt-1 ellipsis font-bold font-15" :title="item.name">{{ item.name }}</span>
            <span class="mx-3 mt-2 ellipsis font-12" :title="item.description">{{ item.description }}</span>
            <div class="mx-3 mt-2 vertical-center">
              <i class="flaticon-placeholder-2 font-12 color-purple"></i>
              <span class="ml-2 ellipsis font-12 color-purple" :title="item.address">{{ item.address }}</span>
              <span class="ml-2 font-12 color-red" v-if="item.hideAddress">{{ $t('private') }}</span>
            </div>
            <div class="mx-3 mt-2 vertical-center justify-content-between">
              <div class="vertical-center ">
                <i class="fa fa-calendar-alt font-12 color-blue"></i>
                <span class="ml-2 font-12">{{ getDateStringFromTimestamp(item.createdAt) }}</span>
              </div>
              <div class="vertical-center" v-if="item.deletedAt">
                <i class="fa fa-calendar-alt font-12 color-red"></i>
                <span class="ml-2 font-12 color-red">{{ getDateStringFromTimestamp(item.deletedAt) }}</span>
                &nbsp;&nbsp;
                <i class="font-12 color-red">[{{ $t('deleted') }}]</i>
              </div>
              <v-btn icon small @click="deleteEventInfoTask(item.eventId)" v-else>
                <i class="fa fa-trash color-red"></i>
              </v-btn>
            </div>
            <div class="m-2 div-divider-h" />
            <span class="m-2 font-12 font-bold color-red" v-if="item.onlyVisibleToMe === true">Only visible to Organizer</span>
            <div class="m-2 d-flex justify-content-between vertical-center" v-else>
              <span class="font-12 font-bold">{{ getDecimalFormat(item.soldQuantity || 0) }} / {{ getDecimalFormat(item.totalQuantity || 0) }}</span>
              <span class="font-15 font-bold color-blue">{{ getPriceFormat(item.netSales || 0) }}</span>
            </div>
            <div class="m-2 d-flex justify-content-end vertical-center">
              <v-btn class="ml-5 button-normal" small @click="$router.push(`/transaction/transactions/${item.eventId}`)" :disabled="!item.netSales">
                <span>{{ $t('transaction') }}</span>
              </v-btn>
              <v-btn class="ml-5 button-red" small @click="$router.push(`/transaction/refund/${item.eventId}`)" :disabled="!item.netSales">
                <span>{{ $t('refund') }}</span>
              </v-btn>
              <v-btn class="ml-5 button-red" small @click="$router.push(`/transaction/withdraws/${item.eventId}`)" :disabled="!item.netSales">
                <span>{{ $t('withdraw') }}</span>
              </v-btn>
          </div>
          </v-card>
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center" v-if="items.length / PAGE_LIMIT_200 > pageIndex">
        <v-btn text small @click="pageIndex++">
          <span>Load More</span>
        </v-btn>
      </div>
      <div class="py-40 center" v-if="(items.length === 0)">
        <span class="font-20 alpha-07">{{ $t('no_data_found') }}</span>
      </div>
    </div>
    <div class="py-40 center" v-else>
      <p>{{ $t('please_wait') }}</p>
      <b-spinner type="grow"></b-spinner>
    </div>
    <!-- VueEasyLightbox Component -->
    <VueEasyLightbox
      :visible="visibleImageViewer"
      :imgs="images"
      :index="imageIndex"
      @hide="visibleImageViewer = false"
      />
    <!-- VueEasyLightbox Component end -->
  </div>
</template>


<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { getDateStringFromTimestamp, getDecimalFormat, getPriceFormat, showLoading } from '../../../functions';

import profile from '@/assets/image/profile.jpg';

export default {
  name: 'EventList',
  components: {
    VueEasyLightbox
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    colSmSize() {
      return this.$store.state.colSmSize;
    },
    organizerList() {
      return this.$store.state.organizerList || [];
    },
    userInfo() {
      if (this.$route.params.userId) {
        return this.organizerList.find(element => element.userId === this.$route.params.userId);
      } else {
        return null;
      }
    },
    eventList() {
      return this.$store.state.eventList;
    },
    params() {
      return this.$route.params;
    }
  },
  watch: {
    params() {
      if (this.keyword) {
        this.keyword = '';
      } else {
        this.refreshData();
      }
    },
    organizerList(newList, oldList) {
      if (oldList.length !== newList.length) {
        this.refreshData();
      }
    },
    eventList(newList, oldList) {
      const oldCount = oldList ? oldList.length : 0;
      const newCount = newList ? newList.length : 0;
      if (oldCount !== newCount) {
        this.refreshData();
      }
    },
    keyword() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        profile
      },
      hostname: window.location.hostname,
      visibleImageViewer: false,
      imageIndex: 0,
      images: [],
      pageIndex: 1,
      cacheUserList: [],
      items: [],
      keyword: ''
    }
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    getDateStringFromTimestamp,
    getDecimalFormat,
    getPriceFormat,
    refreshData() {
      if (this.eventList && this.organizerList) {
        const items = [];
        const keyword = this.keyword.toLowerCase();
        if (this.$route.params.userId) {
          this.eventList.filter(element => element.userId === this.$route.params.userId).forEach(eventInfo => {
            if (eventInfo.name.toLowerCase().includes(keyword)) {
              items.push(eventInfo);
            }
          });
        } else {
          this.eventList.forEach(eventInfo => {
            const userInfo = this.getUserInfo(eventInfo.userId);
            if (userInfo && (eventInfo.name.toLowerCase().includes(keyword) || userInfo.userName.toLowerCase().includes(keyword))) {
              const item = JSON.parse(JSON.stringify(eventInfo));
              item['userName'] = userInfo.userName;
              item['userPhotoUrl'] = userInfo.thumbnailUrl || userInfo.photoUrl || this.assets.profile;
              items.push(item);
            }
          });
        }
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.images = [];
        items.forEach(info => {
          this.images.push({
            title: info.name,
            src: info.photoUrl
          });
        });
        this.pageIndex = 1;
        this.items = items;
      }
    },
    getUserInfo(userId) {
      const cacheInfo = this.cacheUserList.find(element => element.userId === userId);
      if (cacheInfo) {
        return cacheInfo;
      } else {
        const userInfo = this.organizerList ? this.organizerList.find(element => element.userId === userId) : null;
        if (userInfo) {
          this.cacheUserList.push(userInfo);
        }
        return userInfo;
      }
    },
    openImageViewer(index) {
      this.imageIndex = index;
      this.visibleImageViewer = true;
    },
    deleteEventInfoTask(eventId) {
      if (!confirm(this.$t('confirm_delete'))) {
        return;
      }
      const params = {
        functionName: 'deleteEvent',
        eventId: eventId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
          this.refreshData();
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>